import React from "react";

const Services = ({ siteTitle }) => (
  <div className="container-xl services">
    <div className="row">
      <div className="col-xs-4 col-lg-4">
        <div className="service-wrapper">
          <h1>Web / App Development</h1>
          <p>From small presentation site to enterprise applications, we can deliver almost anything.</p>
          <p>We are using the most powerful tool available for back-end and front-end developments</p>
        </div>
      </div>
      <div className="col-xs-4 col-lg-4">
        <div className="service-wrapper">
          <h1>Mobile Development</h1>
          <p>Are you looking for a mobile app for Android or iOS, or both. We can deliver.</p>
          <p>We use native development and tools which combines native development with React</p>
        </div>
      </div>
      <div className="col-xs-4 col-lg-4">
        <div className="service-wrapper">
          <h1>UI & UX Design</h1>
          <p>We deliver beautiful UI designs and UX for web, mobile and desktop applications</p>
          <p>To learn more about our designs, please visit our portfolio website at <a href="http://ildesign.ro">ildesign.ro</a></p>
        </div>
      </div>
    </div>
  </div>
  
);


export default Services;