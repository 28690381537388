import React from "react";

const Offer = ({ siteTitle }) => (
    <div className="container-fluid offer-container">
        <div className="container">
            <h1  className="section-title">How We Are Offering</h1>
            <p className="section-p">We are offering a big scale of development and design services for our customers</p>
            <div className="row  mb-3">
                <div className="col-lg-4 dev-col-4 align-self-stretch">
                    <div>
                        <h3>Developing</h3>
                        <p>High quality code with the latest technology</p>
                    </div>
                </div>
                <div className="col-lg-8 dev-col-8 align-self-stretch">
                    <h4>Tools we use:</h4>
                    <p>Microsoft Visual Studio for .NET Core and ASP.NET MVC, combined with Microsoft Visual Studio Code for front-end development</p>
                    <p>Jetbrains products such as PhpStorm, WebStorm, IntelliJ IDEA Ultimate</p>
                    <p>Compilers like Angular CLI, Gatsby CLI; package managers like Node package manager, Yarn </p>
                </div>
            </div>
            <div className="row  mb-3">
                <div className="col-lg-4 vesrionc-col-4 align-self-stretch">
                    <div>
                        <h3>Version Control & Bug tracking</h3>
                        <p>Fast and relaible Communication</p>
                    </div>
                </div>
                <div className="col-lg-8 vesrionc-col-8 align-self-stretch">
                    <h4>We use:</h4>
                    <p>All Git based source controls like GitHub, GitLab, Bitbucket</p>
                    <p>Tortoise SVN, Commit Monitor</p>
                    <p>Bug and issue trackers: Jira, Mantis Bug Tracker, Zoho BugTracker, Redmin</p>
                    <p>Communication tools: Slack, Skype, email etc...</p>
                </div>
            </div>
            <div className="row  mb-3">
                <div className="col-lg-4 vesrionc-col-4 align-self-stretch">
                    <div>
                        <h3>UX & UI Design</h3>
                        <p>We follow the latest design patterns</p>
                    </div>
                </div>
                <div className="col-lg-8 vesrionc-col-8 design align-self-stretch">
                    <h4>Design tools we use:</h4>
                    <p>All adobe products like Photoshop, XD, Illustrator</p>
                    <p>Web and application based tools like: Figma</p>
                    <p>Tool for iOS: Sketch</p>
                </div>
            </div>
        </div>
    </div>
);


export default Offer;