import React from "react";

const Hero = ({ siteTitle }) => (
  <div className="hero">
    <div className='hero-text'>
      <h1>Welcome, Need Coding?</h1>  
      <p>You are at the best place as possible. Our company provides a variety of softvare development like:</p>
      <p className="list-of-services">mobile app development, website development, desktop application development and UX, UI design</p>
    </div>
    
  </div>
);


export default Hero;