import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";
import Services from "../components/services";
import Slider from "../components/slider";
import Offer from "../components/offer";
import Testimonials from "../components/testimonials";
import Contact from "../components/contact";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <Services />
    <Slider />
    <Offer />
    <Testimonials />
    <Contact />
  </Layout>
);

export default IndexPage;
