import React from "react";

const Contact = () => (
    <div className="container-fluid contact-container">
        <div className="container">
            <h1  className="section-title">Drop us a letter</h1>
            <p className="section-p">
                Our company is located in Sfantu Gheorghe, Romania <br/><br/> 
                aleea Tineretului nr.1, 520064 <br/>
                bl.13, ap.35 <br/><br/>
                &#099;&#111;&#110;&#116;&#097;&#099;&#116;&#064;&#111;&#110;&#101;&#099;&#108;&#105;&#099;&#107;&#097;&#119;&#097;&#121;&#046;&#114;&#111;
                <br /><br />
                One Click Away IT Solutions<br/>
                CUI: 41210786<br/>
                Nr. ORC: J14/339/2019
            </p>
        </div>
    </div>
);


export default Contact;
