import React from "react";
import sasa from "../images/seb-san.jpg";
import sics from "../images/sike-csanad.jpg";
import ll from "../images/luka-laszlo.jpg";
import tacoma from "../images/logo-large-tacoma.jpg";

const customers = [
    {
        location: 'TacomaPhoenix, Puyallup, WA',
        project: 'several project designs',
        text: 'They always does really stellar work, does it on time and is always is very responsive to messages. Will definitely cooperate for more projects',
        image: 'prowebshop.ro',
        personImage: tacoma,
        personName: 'Rex Stevens'
    },
    {
        location: 'HS Timber Productions, Reci',
        project: 'HSR Visitors',
        text: 'It\'s a great team. We built a web based application called HSR visitors. The system used biometric signature pads to let in visitors to the factory. It was a great experience working with these professionals. I recommend them to every company which needs any software',
        image: 'HS Timber, Reci',
        personImage: sasa,
        personName: 'Sándor Sebestyén'
    },
    {
        location: 'ospatarul.ro, Sfantu Gheorghe',
        project: 'ospatarul.ro',
        text: 'We were working together on a food order website www.ospatarul.ro. They did a wonderful good job. They worked fast, the communication went smoothly and in the end the final product was perfect.',
        image: 'ospatarul.ro',
        personImage: sics,
        personName: 'Csanád Sike'
    },
    {
        location: 'prowebshop.ro, Sfantu Gheorghe',
        project: 'prowebshop.ro',
        text: 'Building a powerful e-commerce systems and web based administrative areas for companies is a thought job. The developers and the designers of One Click Away IT Solutions helped a lot in both back-end and front-end. Thank you, I recommend them.',
        image: 'prowebshop.ro',
        personImage: ll,
        personName: 'László Luka'
    }
];

const Testimonial = ({ siteTitle }) => (
    <div className="container-fluid customers-container">
        <div className="container">
            <h1 className="section-title">From Our Customers</h1>
            <p className="section-p">Here you can find testimonials from our satisfied customers</p>
            <div className="row justify-content-around">
                {
                    customers.map((each, index) =>
                        <div className="col-lg-4 mb-5">
                            <div className="location">{each.location}</div>
                            <h3 className="project">{each.project}</h3>
                            <div className="text">{each.text}</div>
                            <div className="person"> 
                                <img key={index} src={each.personImage} alt={each.personName}/>
                                {each.personName}
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    </div>
);


export default Testimonial;